<template>
  <input type="text" ref="f" @keyup="testKeyUp($event)" @input="formatCurrencyKeyDown($event)">
</template>
<script>
export default {
  props: {
    modelValue: Number,
    max:Number
  },
  emits: ['update:modelValue'],
  methods:{
    testKeyUp(event){
      console.log(event.keyCode) // 38 up , 40 down
      if(event.keyCode==38)
        this.formatCurrency((parseFloat(this.modelValue)+1).toString())
      else if(event.keyCode==40)
        this.formatCurrency((parseFloat(this.modelValue)-1).toString())
    },
    formatCurrencyKeyDown(event){

      this.formatCurrency(event.target.value)
    },
    formatCurrency(text){
      if(typeof(text)=='string' && text.length>1 && text[0]=="0" )
        text = text.substring(1)

      var input_val = text?text:"";
      if (input_val === "") { return this.$emit('update:modelValue',0); }
      var original_len = input_val.length;

      // initial caret position
      var caret_pos = this.$refs.f.selectionStart;
      console.log("caret_pos",caret_pos)
      // check for decimal
      if(parseFloat(input_val.replaceAll(",",""))>this.max){
        input_val = this.max
      }
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = this.formatNumber(left_side);

        // validate right side
        right_side = this.formatNumber(right_side);

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = left_side + "." + right_side;

      }
      else {
        input_val = this.formatNumber(input_val);
      }

      this.$refs.f.value = input_val;

      // put caret back in the right position
      var updated_len = input_val.length;
      caret_pos = updated_len - original_len + caret_pos;
      this.$refs.f.setSelectionRange(caret_pos, caret_pos);
      let value = parseFloat(input_val.replaceAll(",",""))
      this.$refs.f.selectionStart = this.$refs.f.selectionEnd = this.$refs.f.value.length;

      this.$emit('update:modelValue',(value?value:0) );
    },
    formatNumber(n) {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  watch:{
    modelValue(){
      this.formatCurrency(this.modelValue?.toString())
    }
  },
  mounted() {
    console.log("this.modelValue",this.modelValue,this.$refs.f)
    this.formatCurrency(this.modelValue?.toString())
  }
}
</script>
<style></style>
